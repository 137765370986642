/* This File is for {purpose} */

<template>
  <apexchart
    width="100%"
    height="300px"
    type="line"
    :options="options"
    :series="series"
  ></apexchart>
</template>

<script>
export default {
  name: "LineChart",
  data: () => ({
    options: {
      chart: {
        id: "vuechart-example",
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "July",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
    },
    series: [
      {
        name: "series-1",
        data: [300, 400, 450, 500, 490, 600, 700, 910, 530, 750, 640, 830],
      },
    ],
  }),
};
</script>

<style scoped></style>
