/* This File is for {purpose} */

<template>
  <v-layout wrap justify-start pa-2 pa-sm-4 pa-lg-8>
    <v-flex xs12>
      <v-layout wrap justify-start>
        <v-flex xs6 lg3 pr-2 pr-lg-4>
          <v-card outlined rounded="lg" class="elevated-card">
            <div class="card-line blue"></div>
            <v-layout wrap justify-start pa-4>
              <v-flex xs12>
                <span class="title-1">246</span> <br />
                <span class="subtitle-1"> Total Employees </span>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs6 lg3 pr-lg-4>
          <v-card outlined rounded="lg" class="elevated-card">
            <div class="card-line green"></div>
            <v-layout wrap justify-start pa-4>
              <v-flex xs12>
                <span class="title-1">200</span> <br />
                <span class="subtitle-1"> no. Assigned Nurse </span>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs6 lg3 pt-2 pt-lg-0 pr-2 pr-lg-4>
          <v-card outlined rounded="lg" class="elevated-card">
            <div class="card-line yellow"></div>
            <v-layout wrap justify-start pa-4>
              <v-flex xs12>
                <span class="title-1">40</span> <br />
                <span class="subtitle-1 text-no-wrap">
                  no. Suspended Nurse
                </span>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs6 lg3 pt-2 pt-lg-0>
          <v-card outlined rounded="lg" class="elevated-card">
            <div class="card-line red"></div>
            <v-layout wrap justify-start pa-4>
              <v-flex xs12>
                <span class="title-1">06</span> <br />
                <span class="subtitle-1"> no. Nurse on leave </span>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-start pt-4>
        <v-flex xs12>
          <v-card class="custom-card">
            <v-layout wrap justify-start pa-4>
              <v-flex xs12 class="title-2">Employees</v-flex>
              <v-flex xs12>
                <LineChart />
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-start pt-4>
        <v-flex xs12>
          <v-card class="custom-card">
            <v-layout wrap justify-start pa-4>
              <v-flex xs12 class="title-2">Hospitals</v-flex>
              <v-flex xs12>
                <BarChart />
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import LineChart from "@/components/charts/LineChart";
import BarChart from "@/components/charts/BarChart";
export default {
  name: "Dashboard",
  components: { BarChart, LineChart },
  data: () => ({
    page: 1,
  }),
  mounted() {
    const breadCrumbs = [
      {
        name: this.$route.name,
        text: "Dashboard",
      },
    ];
    this.$store.commit("app/setBreadCrumbs", breadCrumbs);
  },
};
</script>

<style scoped>
.custom-card {
  border: 1px solid #cfd8e9 !important;
  box-shadow: 0px 3px 10px #eaedf1 !important;
  border-radius: 7px !important;
}
</style>
