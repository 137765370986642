var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    {
      attrs: {
        wrap: "",
        "justify-start": "",
        "pa-2": "",
        "pa-sm-4": "",
        "pa-lg-8": "",
      },
    },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs6: "", lg3: "", "pr-2": "", "pr-lg-4": "" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "elevated-card",
                      attrs: { outlined: "", rounded: "lg" },
                    },
                    [
                      _c("div", { staticClass: "card-line blue" }),
                      _c(
                        "v-layout",
                        {
                          attrs: { wrap: "", "justify-start": "", "pa-4": "" },
                        },
                        [
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("span", { staticClass: "title-1" }, [
                              _vm._v("246"),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _c("span", { staticClass: "subtitle-1" }, [
                              _vm._v(" Total Employees "),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs6: "", lg3: "", "pr-lg-4": "" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "elevated-card",
                      attrs: { outlined: "", rounded: "lg" },
                    },
                    [
                      _c("div", { staticClass: "card-line green" }),
                      _c(
                        "v-layout",
                        {
                          attrs: { wrap: "", "justify-start": "", "pa-4": "" },
                        },
                        [
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("span", { staticClass: "title-1" }, [
                              _vm._v("200"),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _c("span", { staticClass: "subtitle-1" }, [
                              _vm._v(" no. Assigned Nurse "),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  attrs: {
                    xs6: "",
                    lg3: "",
                    "pt-2": "",
                    "pt-lg-0": "",
                    "pr-2": "",
                    "pr-lg-4": "",
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "elevated-card",
                      attrs: { outlined: "", rounded: "lg" },
                    },
                    [
                      _c("div", { staticClass: "card-line yellow" }),
                      _c(
                        "v-layout",
                        {
                          attrs: { wrap: "", "justify-start": "", "pa-4": "" },
                        },
                        [
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("span", { staticClass: "title-1" }, [
                              _vm._v("40"),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _c(
                              "span",
                              { staticClass: "subtitle-1 text-no-wrap" },
                              [_vm._v(" no. Suspended Nurse ")]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs6: "", lg3: "", "pt-2": "", "pt-lg-0": "" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "elevated-card",
                      attrs: { outlined: "", rounded: "lg" },
                    },
                    [
                      _c("div", { staticClass: "card-line red" }),
                      _c(
                        "v-layout",
                        {
                          attrs: { wrap: "", "justify-start": "", "pa-4": "" },
                        },
                        [
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("span", { staticClass: "title-1" }, [
                              _vm._v("06"),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _c("span", { staticClass: "subtitle-1" }, [
                              _vm._v(" no. Nurse on leave "),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "", "pt-4": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "custom-card" },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: { wrap: "", "justify-start": "", "pa-4": "" },
                        },
                        [
                          _c(
                            "v-flex",
                            { staticClass: "title-2", attrs: { xs12: "" } },
                            [_vm._v("Employees")]
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [_c("LineChart")],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "", "pt-4": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "custom-card" },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: { wrap: "", "justify-start": "", "pa-4": "" },
                        },
                        [
                          _c(
                            "v-flex",
                            { staticClass: "title-2", attrs: { xs12: "" } },
                            [_vm._v("Hospitals")]
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [_c("BarChart")],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }