var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("apexchart", {
    attrs: {
      width: "100%",
      height: "300px",
      type: "line",
      options: _vm.options,
      series: _vm.series,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }